<template>
  <div>
    <img
      src="@/assets/images/placeholders/cow-alien.svg"
      class="block"
      style="height: 200px"
      alt="page not found"
    />
  </div>
</template>

<script>
export default {
  name: 'RouteNotFound',
  head: {
    title: {
      inner: 'Page Not Found'
    }
  }
}
</script>
